<template>
  <div class="balance">
    <div class="title">余额管理</div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="平安银行">
        <a-card :loading="spinLoading">
          <a-row :gutter="42" type="flex">
            <a-col :span="8" class="border">
              <p class="moneyTitle">账户余额</p>
              <template v-if="info.balanceStatus === 0">
                <div class="bank-nobind text-center">
                  <a-popconfirm
                    v-if="info.balanceStatus === 0"
                    title="请确定是否开通"
                    @confirm="openPay"
                  >
                    <a-button  class="balance-btn noOpen" :loading="subAccountLoading">开通余额支付</a-button>
                  </a-popconfirm>
                </div>
              </template>
              <template v-else>
              <p class="moneyAcount text-center">￥{{info.totalBalance}}</p>
              <div class="text-center">
                <a-space style="margin-top: 12px">
                  <a-button type="primary" @click="visible = true" v-if="info.balanceStatus === 2">充值</a-button>
                  <a-button type="primary" v-if="info.balanceStatus === 1 || info.balanceStatus === 2" @click="paInfo">余额</a-button>
                </a-space>
              </div>
              </template>
            </a-col>
            <a-col :span="8" class="border" v-if="false">
              <p class="moneyTitle">本月记录</p>
              <p class="record O6D" style="padding-top: 20px">
                转入：<span class="OA5 recordMoney">
                  <span class="red">￥ </span>0.00</span>
              </p>
              <p class="record O6D">
                转出：<span class="OA5 recordMoney">
                  <span class="red">￥ </span>0.00</span>
              </p>
            </a-col>
            <a-col :span="8">
              <p class="moneyTitle">银行卡</p>
              <!-- 已绑卡 -->
              <template v-if="info.balanceStatus === 2">
                <div class="bank-card">
                  <div class="icon">
                    <img :src="iconBank(info.bankName)" alt="">
                  </div>
                  <p><b>{{info.bankName}}</b></p>
                  <p class="bank-code">{{info.bankCodeNo}}</p>
                  <p class="bank-num">{{str(info.bankAccount)}}</p>
                </div>
              </template>
              <!-- 未绑卡 -->
              <template v-else>
                <div class="bank-nobind text-center" v-if="info.balanceStatus === 1 && !info.enableContinueBindCard">
                  <a-button type="primary" class="balance-btn noBind" @click="$refs.entityBind.toBind()">提现绑卡</a-button>
                </div>
                <!-- 未鉴权 -->
                <a-space v-if="info.balanceStatus === 1 && info.enableContinueBindCard"  class="space-nobind text-center" direction="vertical">
                  <p>{{info.continueBindBankInfo.bankName}} ---- {{info.continueBindBankInfo.bankAccount}}</p>
                  <a-space>
                    <a-button class="balance-btn noBind" @click="$refs.entityBind.toAAA()">继续鉴权</a-button>
                    <a-button type="primary" class="balance-btn noBind" @click="$refs.entityBind.toBind()">重新绑卡</a-button>
                  </a-space>
                </a-space>
              </template>
            </a-col>
          </a-row>
        </a-card>
        <a-card :tab-list="tabList" :active-tab-key="tabKey" @tabChange="tabChange">
          <div v-show="tabKey === 'tab1'">
            <a-space :size="20" class="space-b" v-if="false">
              <span>
                交易时间：
                <a-space>
                  <a-date-picker :value="dealForm.startDate" @change="dealForm.startDate = $event ? $event.format('YYYY-MM-DD') : undefined" placeholder="开始日期" :disabled-date="disabledStartDate" :disabled="dealForm.timeType === 0">
                  </a-date-picker>
                  -
                  <a-date-picker :value="dealForm.endDate" @change="dealForm.endDate = $event ? $event.format('YYYY-MM-DD') : undefined" placeholder="结束日期" :disabled-date="disabledEndDate" :disabled="dealForm.timeType === 0">
                  </a-date-picker>
                </a-space>
              </span>
              <span>流向：
                <a-select style="width: 120px" v-model="dealForm.queryFlag">
                  <a-select-option :key="1"> 全部 </a-select-option>
                  <a-select-option :key="2"> 转出 </a-select-option>
                  <a-select-option :key="3"> 转入 </a-select-option>
                </a-select>
              </span>
              <span>时间类型：
                <a-select style="width: 120px" v-model="dealForm.timeType">
                  <a-select-option :key="0"> 今天 </a-select-option>
                  <a-select-option :key="1"> 历史 </a-select-option>
                </a-select>
              </span>
              <a-button type="primary" @click="dealForm.pageNum = 1;purchaserTradeList('dealForm')">查询</a-button>
            </a-space>

            <a-table :rowKey="(record,index)=>{return index}" :columns="columns" size="middle" :loading="dealForm.loading" :data-source="dealForm.data" :pagination="{
                size: 'middle',
                total: dealForm.total,
                current: dealForm.pageNum,
                pageSize: dealForm.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50', '100'],
                showTotal: (total) => `共有 ${dealForm.total} 条数据`,
                showQuickJumper: true,
                onShowSizeChange: (current, pageSize) => {
                  dealForm.pageSize = pageSize
                  dealForm.pageNum = 1
                  this.purchaserTradeList('dealForm')
                },
                onChange: (pageNum) => {
                  dealForm.pageNum = pageNum
                  this.purchaserTradeList('dealForm')
                }
              }">
              <span slot="tradeType" slot-scope="text">{{ text | tradeType }}</span>
              <span slot="tradeStatus" slot-scope="text">{{ text | tradeStatus }}</span>
              <a-tooltip placement="top" slot="remark" slot-scope="text"><template slot="title">{{ text }}</template>{{text}}</a-tooltip>
              <span slot="orderId" slot-scope="text,record">
                <router-link :to="`/order?id=${record.orderId}&entityId=${$route.query.entityId}`">{{ text }}</router-link>
              </span>
            </a-table>
          </div>
        </a-card>
        <!-- 平安充值 -->
        <a-modal :width="660" v-model="visible" class="remind" title="平安银行充值提醒" :visible="visible" centered :closable="false" footer="">
          <p class="fs16">现有余额：<span class="red">￥{{info.totalBalance}}</span></p>
          <!-- <p class="payMoney">
            <a-space :size="20">
              <span>支付申请金额：￥86,500.500</span>
              <span><b class="red">
                  <a-icon type="exclamation-circle" />
                </b> 余额不足支付，还需充值 ￥29,619.50</span>
            </a-space>
          </p> -->
          <p class="fs16 fw700 pt20">请使用绑定的银行卡向91平台总账充值：</p>
          <p class="pl20 fs16 p-code">绑定银行卡号：<b>{{info.bankAccount}}</b></p>
          <p class="fs16 fw700 pt20"><b>平安银行91再生网平台充值账号</b></p>
          <p class="pl20 fs16 p-code">收款户名：<b>平安银行电子商务交易资金待清算专户（91再生平台）</b></p>
          <p class="pl20 fs16 p-code">收款账号：<b>15000098823159</b></p>
          <p class="pl20 fs16 p-code">收款银行：<b>平安银行杭州分行</b></p>
          <div class="text-center"  style="padding-top: 28px">
            <a-button @click="visible = false" type="primary">确定</a-button>
          </div>
        </a-modal>
      </a-tab-pane>
    </a-tabs>
    <entityBindStep class="entitybind" ref="entityBind" :entityBank="bankList.length && bankList" @ok="paInfo();"/>
    <open-pay ref="openSuccess" @ok="$refs.entityBind.toBind()"></open-pay>
    <payPwd ref="payPwd"/>
  </div>
</template>
<script>
import {paInfo, openSubAccountPurchaser,purchaserTradeList} from '@/api/pa'
import openPay from '@/components/paBind/openPay'
import entityBindStep from '@/components/paBind/entityBindStep'
import { getbanklist } from '@/api/bank'
import {paTallyType, tradeType, tradeStatus} from '@/utils/constArr'
import payPwd from '@/components/payPwd'
import moment from 'moment'
const columns = [
  {
    title: '创建时间',
    dataIndex: 'createdTime'
  },
  {
    title: '订单ID',
    width: 60,
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    title: '支付编号',
    dataIndex: 'paymentNo',
  },
  {
    title: '出金账号',
    dataIndex: 'outAccountNum',
  },
  {
    title: '入金账号',
    dataIndex: 'inAccountNum',
  },
  {
    title: '金额',
    dataIndex: 'amount',
    align: 'right'
  },
  {
    title: '类型',
    width: 50,
    dataIndex: 'tradeType',
    scopedSlots: { customRender: 'tradeType' }
  },
  {
    title: '状态',
    dataIndex: 'tradeStatus',
    width: 72,
    scopedSlots: { customRender: 'tradeStatus' }
  },
  {
    title: '备注',
    scopedSlots: { customRender: 'remark' },
    dataIndex: 'remark',
    ellipsis: true
  }
]

export default {
  components: {
    openPay,
    entityBindStep,
    payPwd
  },
  data() {
    return {
      columns,
      spinLoading: false,
      subAccountLoading: false,
      bankList: [],
      info: {
        totalBalance: 0,
        balanceStatus: 0,
        bankAccount: undefined,
        bankName: undefined,
        phone: undefined,
        enableContinueBindCard: false,
        continueBindBankInfo: {},
        bankCodeNo: undefined
      },
      tabList: [
        {
          key: 'tab1',
          tab: '交易明细',
        },
        // {
        //   key: 'tab2',
        //   tab: '充值明细',
        // }
      ],
      tabKey: 'tab1',
      dealForm: {
        data: [],
        pageNum: 1,
        pageSize: 5,
        queryFlag: 1,
        purchaserId: this.$route.query.entityId,
        timeType: 1,
        startDate:  moment().add(-1,'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        total: 0,
        loading: false
      },
      visible: false,
      bankIconList: [
          {name: '工商银行', imgUrl: require("@/assets/images/bank/bankGS.png")},
          {name: '光大银行', imgUrl: require("@/assets/images/bank/bankGD.png")},
          {name: '华夏银行', imgUrl: require("@/assets/images/bank/bankHX.png")},
          {name: '建设银行', imgUrl: require("@/assets/images/bank/bankJS.png")},
          {name: '交通银行', imgUrl: require("@/assets/images/bank/bankJT.png")},
          {name: '农业银行', imgUrl: require("@/assets/images/bank/bankNY.png")},
          {name: '平安银行', imgUrl: require("@/assets/images/bank/bankPA.png")},
          {name: '浦发银行', imgUrl: require("@/assets/images/bank/bankPF.png")},
          {name: '人民银行', imgUrl: require("@/assets/images/bank/bankRM.png")},
          {name: '兴业银行', imgUrl: require("@/assets/images/bank/bankXY.png")},
          {name: '招商银行', imgUrl: require("@/assets/images/bank/bankZS.png")},
          {name: '中信银行', imgUrl: require("@/assets/images/bank/bankZX.png")},
      ],
    }
  },
  created() { 
    this.paInfo()
    this.purchaserTradeList('dealForm')
    getbanklist({ purchaserId: this.$route.query.entityId }).then((res) => {
      this.bankList = res.data
    })
  },
  filters: {
    type: (val) => paTallyType[val],
    tradeType: (val) => tradeType[val] || '',
    tradeStatus: (val) => tradeStatus[val] || ''
  },
  methods: {
    tabChange (e) {
      this.tabKey = e
      if (e === 'tab1') {
        this.purchaserTradeList('dealForm')
      }
    },
    iconBank(val) {
      let url = require("@/assets/images/bank/all.png")
      this.bankIconList.forEach(item => {
          if (val && val.indexOf(item.name) !== -1) {
              url = item.imgUrl
          }
      })
      return url
    },
    str(val) {
      console.log('222',val);
        if (!val) return
        const suffix = val.substring(val.length - 4)
        const prefix = val.substring(0,5)
        return prefix + '******' + suffix
    },
    paInfo () {
      this.spinLoading = true
      paInfo({type: 'P', entityId: this.$route.query.entityId}).then(res => {
        this.spinLoading = false
        if (res.code === 200) {
          const {totalBalance,balanceStatus,bankAccount,bankName,phone,enableContinueBindCard,continueBindBankInfo,bankCodeNo} = res.data
          this.info = {totalBalance,balanceStatus,bankAccount,bankName,phone,enableContinueBindCard,continueBindBankInfo,bankCodeNo}
        } else {
          this.$message.error(res.message)
        }
      })
    },
    openPay () {
       this.subAccountLoading = true
       openSubAccountPurchaser({purchaserId: this.$route.query.entityId}).then(res => {
        this.subAccountLoading = false
        if (res.code ===200) {
          this.paInfo()
          this.$refs.openSuccess.isPay = true
        } else {
          this.$message.error(res.message)
        }
       })
     },
    purchaserTradeList (str) {
      const form = Object.assign({}, this[str])
      if (this[str].timeType === 0) {
        form.startDate = undefined
        form.endDate = undefined
      } else {
        if (form.endDate && !form.startDate) {
          this.$message.error('请选择开始日期')
          return
        } else if (form.startDate && !form.endDate) {
          this.$message.error('请选择结束日期')
          return
        }
      }
      this[str].loading = true
      purchaserTradeList(form).then(res => {
        this[str].loading = false
        if (res.code ===200) {
          this[str].data = res.data.list
          this[str].total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    disabledDate (current) {
      // if (this.dealForm.startDate === moment().format('YYYY-MM-DD')) {

      // }
      return current > moment()
    },
    disabledStartDate (current) {
      if (this.dealForm.endDate) {
        return current > moment(this.dealForm.endDate) || current > moment()
      }
      return current > moment()
    },
    disabledEndDate (current) {
      if (this.dealForm.startDate) {
        return current < moment(this.dealForm.startDate) || current > moment()
      }
      return current > moment()
    },
  }
}
</script>
<style lang="less" scoped>
.balance {
  background-color: #fff;
  padding: 20px;
  .title {
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 20px;
  }
  .moneyTitle {
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: rgba(0, 0, 0);
    line-height: 22px;
  }
  .moneyAcount {
    color: rgba(253, 89, 0);
    padding-top: 10px;
    font-size: 30px;
  }
  .record {
    padding: 10px 0 0 20px;
  }
  .recordMoney {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
  .pt10 {
    padding-top: 10px;
  }

  .O6D {
    color: #0000006d;
  }
  .OA5 {
    color: #000000a5;
  }
}
.hint {
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  background-color: rgba(128, 255, 255, 1);
}
.count {
  padding: 10px 0 30px 70px;
  line-height: 50px;
}
.red {
  color: red;
}
.payMoney {
  margin-top: 10px;
  padding: 10px 0;
  border-radius: 5px;
  background-color: rgb(242, 242, 242);
}
.fs16 {
  font-size: 16px;
}
.fw700 {
  font-weight: 700;
  color: #666;
}
.pt20 {
  padding-top: 8px;
}
.pl20 {
  padding-left: 20px;
}
.pl50 {
  padding-left: 50px;
}

/deep/.ant-modal-title {
  font-size: 18px;
}
/deep/ .ant-card {
  margin-top: 30px;
}
/deep/.ant-modal-footer {
  text-align: center;
}
.text-center {
  text-align: center;
}
.remind {
  .red {
    font-size: 18px;
    color: #D9001B;
  }
  p {
    line-height: 26px;
  }
  b {
    color: #666;
  }
  .p-code {
    line-height: 32px;
  }
  /deep/ .ant-modal {
    color: #666;
  }
  /deep/ .ant-modal-body {
    padding: 24px 54px;
  }
}
.bank-card {
  margin-top: 16px;
  max-width: 300px;
  height: 111px;
  background-color: #5bb0ff;
  border-radius: 5px;
  padding: 16px 0;
  padding-left: 54px;
  position: relative;
  color: #fff;
  .bank-code {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .bank-num {
    font-size: 16px;
    line-height: initial;
  }
  .icon {
    position: absolute;
    left: 0;
    width: 42px;
    height: 42px;
    background: #bfeef7;
    // border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    img {
      width: 42px;
      height: 42px;
    }
  }
  .select {
    position: absolute;
    right: 5px;
    bottom: 5px;
    i {
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: @error;
      }
    }
  }
  .select-check {
    color: @error;
  }
}
.space-b {
  margin-bottom: 20px;
}
.border {
  border-right: 1px solid #e1c0ff;
}
.space-nobind {
  padding: 32px 0;
  width: 100%;
}
.bank-nobind {
  padding: 32px 0;
  width: 100%;
}
</style>